import Head from 'next/head';
import Link from 'next/link';
import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import style from './404.module.css';
import { Loader } from '../../components/Loader/Loader';
import { useLoader } from '../../hooks/useLoader';
import Script from 'next/script';

export default function Page404() {
  const loading = useLoader();
  return (
    <>
      <Head>
        <meta key="utf" charSet="UTF-8" />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          key="facebook"
          name="facebook-domain-verification"
          content="5q9rm986matfhco68cm9zmwzh78kw4"
        />
        <title>CoSupport AI | 404</title>
        <Script
          id="googletagmanager3"
          key="googletagmanager3"
          strategy="beforeInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=AW-11237889467"
        />
        <Script
          id="googletagmanager4"
          key="googletagmanager4"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-11237889467');
          `,
          }}
        />
        <Script
          id="googletagmanager5"
          key="googletagmanager5"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-WXHFTKL9');
          `,
          }}
        />
        <Script
          id="dealfront"
          key="dealfront"
          dangerouslySetInnerHTML={{
            __html: `(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('JMvZ8gnv0VPa2pOd');`,
          }}
        />
      </Head>
      {loading && <Loader />}
      <Header pathname={`/404`} />
      <main>
        <div className={style.container}>
          <div className={style.content}>
            <h1 className={'font-formula'}>404</h1>
            <p>Oops! Even CoSupport hits a glitch. This page? Not in our database.</p>
            <div className={style.buttonContainer}>
              <Link href="/" prefetch={false}>
                <button className="green-button px-12">
                  <div className="button-text">
                    <span>Restart The Matrix</span>
                  </div>
                  <div className="gradient"></div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
